import { BaseModel } from "./base";

export class ReservoirWeeklyReport extends BaseModel {

    
    senderName: string;

    subject: string;

    sentDate: Date;

    fromDate: Date;

    toDate: Date;

    Activities: Array<ActivitiesDetails>;
    comments:string;
}
export class ActivitiesDetails  {
 
    
    Name: string;
    
    Activity?: string;
 
}