import { BaseModel } from './base';

export class PVTReportDto extends BaseModel {
  fromDate: Date;
  toDate: Date;
  challenges: string;
  currentJobs: PVTCurrentJob[];
  expectedJobs: PVTExpectedJob[];
  fieldServices: PVTFieldService[];
  invoices: PVTInvoice[];
  faultyEquipments: PVTFaultyEquipment[];
  localPurchaseExpenses: PVTlocalPurchaseExpense[];
  overseasPurchases: PVTOverseasPurchase[];
  senderId : string;
  senderName : string;
  comments: string;
}

export class PVTCurrentJob {
  client: string;
  job: string;
  workUpdate: string;
  clientFocalPoint: string;
  lesarFocalPoint: string;
}

export class PVTExpectedJob {
  client: string;
  job: string;
  clientFocalPoint: string;
  lesarFocalPoint: string;
}

export class PVTFieldService {
  client: string;
  location: string;
  description: string;
  status: string;
  personel: string;
}

export class PVTInvoice {
  client: string;
  currency: string;
  analysis: string;
  invoiceNumber: string;
  amount: number;
}

export class PVTFaultyEquipment {
  equipment: string;
  challenge: string;
  actionTaken: string;
}

export class PVTlocalPurchaseExpense {
  date: Date;
  item: string;
  amount: number;
}

export class PVTOverseasPurchase {
  description: string;
  oem: string;
  currency: string;
  dueDate: Date;
  amount: number;
  payment: number;
  remarks: string;
}
