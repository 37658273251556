import { BaseModel } from "./base";

export class FrontDeskReport extends BaseModel {
  id: string;
  senderId: string;
  senderName: string;
  reportDetails: Array<recordDetails>;
  fromDate: Date;
  toDate: Date;
  date: Date;
  periodEnding: Date;
  comments:string;
}

export class recordDetails {
  item: string;
  description: string;
  remark: string;
}
