import { BaseModel } from "./base";

export class ItReport extends BaseModel {
  ItReport: It[];
  id: string;
  toDate: Date;
  fromDate: Date;
  senderId: string;
  senderName: string;
  comments:string;
}

export class It {
  Name: string;
  IssuesActionsTaken: string;
  Remark: string;
}
