import { BaseModel } from './base';
import { UserDto } from './user';

export class BiddingDto extends BaseModel {
  from: Date;

  to: Date;
  senderName: string;
  achievement: string;
  subject: string;
  bidSummaries: BidSummaryDto[];

  portalRecords: BidPortalDto[];
  comments:string;
}

export class BidSummaryDto {
  bidding: string;

  company: string;

  type: string;

  dueDate: Date;

  stageDate: Date;

  unit: string;

  mode: string;

  userId: UserDto | string;
}

export class BidPortalDto {
  Portal: string;

  status: string;

  remark: string;
}
